import React from "react";
import heropic from "../../assets/hero.png";

const HeroSection = () => {
  return (
    <div className="w-[84%]  mx-auto md:flex justify-between  caret-transparent" id="hero">
      <div className="md:w-[47.5%] md:py-10">
        <span className="font-Poppins text-[17px] text-[#4159A7] font-[500] border-b-[3px] border-[#4159A7] ">
          Future With Prodigia
        </span>
        <h1 className="xl:text-5xl lg:text-4xl md:text-4xl sm:text-3xl text-2xl  font-[600] font-poppins">
          The Future of Digital Innovation Unfolds
        </h1>
        <p className="font-poppins font-[300] sm:text-lg sm:w-[90%] text-[#000000] lg:leading-[37px] md:leading-[25px] ">
          Prodigia solve the world's biggest problems with expertise. Prodigia
          helps global brand with digital products on web, mobile and connected
          platforms.
        </p>
       
      </div>
      <div  className="md:w-[39.5%] w-[50%] mx-auto">
        <img src={heropic}  />
      </div>
    </div>
  );
};

export default HeroSection;
