import React from "react";
import companypic1 from "../../assets/company1.png";
import companypic2 from "../../assets/company2.png";
import companypic3 from "../../assets/company3.png";
import companypic4 from "../../assets/company4.png";
import person from "../../assets/person.png";
import Slider from "./Slider";
import sliderData from "../../sliderData";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";

import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

const Testimonials = () => {
  return (
    <div className="w-[100%] h-auto bg-[#EAEFFF] p-5" id="testimonial">
      <div className="xl:w-[84.72%] md:w-[90%] mx-auto  md:flex justify-between">
        <div className="md:w-[46.14%] h-auto  flex flex-col justify-around">
          <div className="">
            <span className="font-Poppins text-[17px] text-[#4159A7] font-[500] border-b-[3px] border-[#4159A7] ">
              Our Reviews
            </span>
            <h1 className="xl:text-5xl lg:text-4xl md:text-4xl  font-[600] font-poppins">
              Client Testimonials
            </h1>
          </div>
        </div>
        <Slider slides={sliderData} />
      </div>
    </div>
  );
};

export default Testimonials;
